<template>
  <head>
    <link rel="shortcut icon" type="image/png" href="../public/beer-match-favicon-color.png"/>
  </head>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark" id="nav-bar">
      <router-link v-if="$store.state.loggedIn" to="/beers" class="navbar-brand"><img src="../public/logo.png" alt="Beer Match" height="40" width="40"></router-link>
      <router-link v-if="!$store.state.loggedIn" to="/" class="navbar-brand"><img src="../public/logo.png" alt="Beer Match" height="40" width="40"></router-link>
      <div class="navbar-nav mr-auto">
        <li class="nav-item">
          <router-link v-if="$store.state.loggedIn && $store.state.currentUser.brewery_auth == 1" to="/brewery-admin" class="nav-link">Admin</router-link>
        </li>
        <li class="nav-item">
          <router-link v-if="!$store.state.loggedIn" to="/create-account" class="nav-link">Create Account</router-link>
          <a id="logout" v-if="$store.state.loggedIn" @click="logOutUser()" class="nav-link">Log Out</a>
        </li>
      </div>
      <div v-if="$store.state.loggedIn" class="nav navbar-nav navbar-right">
        <li>
          <span class="nav-link">{{ $store.state.currentUser.first_name }} &#9660;</span>
        </li>
      </div>
    </nav>
    <div class="container mt-3">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>



<script>
import BeerDataService from "./services/BeerDataService";
import router from './router';
import store from './store';

export default {
  name: "app",
  methods: {
    logOutUser() {
      var user = store.state.currentUser;
      BeerDataService.logout(user)
        .then(response => {
          console.log(response.status);
          store.dispatch('removeToken');
          store.dispatch('logoutUser', null);
          router.push('/');
        })
        .catch(e => {
          console.log(e);
        });
      
    },
  },
  created() {
    document.title = "Beer Match";
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open Sans');

html, body {
  font-family: 'Open Sans', sans-serif;
  background-color: #F9F9F9;
}

#app {
  background-color: #F9F9F9;
  font-family: 'Open Sans', sans-serif;
}

#nav-bar {
  border-bottom: 3px solid #FDC500;
}

.nav-link {
  color: #FFFFFF;
}

#logout {
  cursor: pointer;
}

</style>