import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from './router';
import store from "./store";
import BeerDataService from "./services/BeerDataService";

const app = createApp(App);

app.use(router);
app.use(store);

app.directive('mask', {
	mounted(el, binding) {
    
    var mask = binding.value,
        first = mask.indexOf('_'),
        clean = mask.replace(/[^0-9_]/gm, ''),
        indexes = []
        
    for(var i = 0; i < clean.length; i++){
		if(!isNaN(clean[i])){
			indexes.push(i)
		}
    }
    
    el.value = mask
    el.clean = mask.replace(/[^0-9]/gm, '')
    
    function maskIt() { 
		var value = el.value, 
			filtred = value.replace(/[^0-9]/gm, ''),
			result = ''
      
		if (value.length < first) {
			value = mask + value
			filtred = value.replace(/[^0-9]/gm, '')
		}
      
		for (var i = 0; i < filtred.length; i++) {
			if (indexes.indexOf(i) == -1) {
				result += filtred[i]
			}
		}
      
		value = ''
		var cursor = 0

		for (var j = 0; j < mask.length; j++) {
			if (mask[j] == '_' && result) {
				value += result[0]
				result = result.slice(1)
				cursor = j + 1
			} else {
				value += mask[j]
			}
		}

		if (cursor < first) {
			cursor = first
		}

		el.value = value
		el.clean = el.value.replace(/[^0-9]/gm, '')
		el.setSelectionRange(cursor,cursor)
    }
    
    el.addEventListener('focus', function(event){
		event.preventDefault()
    })
    
    el.addEventListener('click', function(event){
		event.preventDefault()
		var start = el.value.indexOf('_')

		if (start == -1) {
			start = el.value.length
		}

		el.setSelectionRange(start,start)
    })
    
    el.addEventListener('paste', function(event){
		console.log(event)
		var start = el.selectionStart

		if (start < first) {
			el.value = '_' + el.value
		}
    })
    
    el.addEventListener('input', function(event){
		console.log(event)
		maskIt()
    })
  }
})

async function initializeStore() {
	store.dispatch('initializeStore');
}

async function authenticateUser() {
	// authenticate token to get user info
	await BeerDataService.authUser()
	.then(response => {
		store.dispatch('setCurrentUser', response.data);
	})
	.catch(e => {
		console.log(e);
	});
	
	// get csrf token value
	let name = 'csrftoken' + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			store.dispatch('setCSRFToken', c.substring(name.length, c.length));
		}
	}
}

async function authenticateAndRun() {
	await authenticateUser();
	app.mount('#app');
}

async function runApp() {
	await initializeStore();
	
	if (store.state.token) {
		authenticateAndRun();
	} else {
		app.mount('#app');
	}
}

runApp();
