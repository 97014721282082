import { createStore } from 'vuex';
import router from '../router';

export default createStore({
	state: {
		quizComplete: false, 
		currentUser: null,
		userPossessive: '',
		breweryPath: null,
		breweryID: null,
		breweryName: null,
		loading: true,
		token: '',
		loggedIn: false,
		authHeader: '',
		csrfToken: ''
	},
	mutations: { // mutations should be mapped one to one with state variables (e.g. Update; NOT increase/decrease)
		UPDATE_AUTH_HEADER(state, payload) {
			state.authHeader = payload;
		}, 
		UPDATE_TOKEN(state, payload) {
			state.token = payload;
		},
		UPDATE_LOGIN_STATE(state, payload) {
			state.loggedIn = payload;
		},
		UPDATE_CURRENT_USER(state, payload) {
			state.currentUser = payload;
		},
		UPDATE_USER_POSSESSIVE(state, payload) {
			state.userPossessive = payload.first_name.slice(-1) == 's' ? `${payload.first_name}'` : `${payload.first_name}'s`;
		},
		UPDATE_QUIZ_COMPLETE(state, payload) {
			state.quizComplete = payload;
		},
		UPDATE_BREWERY_PATH(state, payload) {
			state.breweryPath = payload;
		},
		UPDATE_BREWERY_ID(state, payload) {
			state.breweryID = payload;
		},
		UPDATE_BREWERY_NAME(state, payload) {
			state.breweryName = payload;
		},
		UPDATE_LOADING(state, payload) {
			state.loading = payload;
		},
		UPDATE_CSRF_TOKEN(state, payload) {
			state.csrfToken = payload;
		}
	},
	actions: { // multiple actions (e.g. increase, decrease) can be associated with one mutation (update)
		loginUser(context, payload) { 
			context.commit('UPDATE_CURRENT_USER', payload);
			context.commit('UPDATE_USER_POSSESSIVE', payload);
			context.commit('UPDATE_LOGIN_STATE', true);
			if (payload.crisp === null) {
				context.commit('UPDATE_QUIZ_COMPLETE', false);
			} else {
				context.commit('UPDATE_QUIZ_COMPLETE', true);
			}
		},
		logoutUser(context, payload) {
			context.commit('UPDATE_CURRENT_USER', payload);
			context.commit('UPDATE_LOGIN_STATE', false);
			context.commit('UPDATE_QUIZ_COMPLETE', false);
			context.commit('UPDATE_BREWERY_PATH', null);
			context.commit('UPDATE_BREWERY_ID', null);
			context.commit('UPDATE_BREWERY_NAME', null);
			router.push('/');
		},
		setBreweryPath(context, payload) {
			context.commit('UPDATE_BREWERY_PATH', payload);
		},
		setBreweryID(context, payload) {
			context.commit('UPDATE_BREWERY_ID', payload);
		},
		setBreweryName(context, payload) {
			context.commit('UPDATE_BREWERY_NAME', payload);
		},
		setLoading(context, payload) {
			context.commit('UPDATE_LOADING', payload);
		},
		setToken(context, payload) {
			context.commit('UPDATE_TOKEN', payload);
			context.commit('UPDATE_AUTH_HEADER', "Token " + payload);
			localStorage.setItem('token', payload);
		},
		removeToken(context) {
			context.commit('UPDATE_TOKEN', '');
			context.commit('UPDATE_AUTH_HEADER', '');
			localStorage.removeItem('token');
		},
		setCSRFToken(context, payload) {
			context.commit('UPDATE_CSRF_TOKEN', payload);
		},
		initializeStore(context) {
			const token = localStorage.getItem('token');
			if (token) {
				context.commit('UPDATE_TOKEN', token);
				context.commit('UPDATE_AUTH_HEADER', "Token " + token);
			} else {
				context.commit('UPDATE_TOKEN', '');
				context.commit('UPDATE_LOGIN_STATE', false);
				context.commit('UPDATE_QUIZ_COMPLETE', false);
				context.commit('UPDATE_CURRENT_USER', null);
			}
		},
		setCurrentUser(context, payload) {
			context.commit('UPDATE_CURRENT_USER', payload);
			context.commit('UPDATE_USER_POSSESSIVE', payload);
			context.commit('UPDATE_LOGIN_STATE', true);
			if (payload.crisp === null) {
				context.commit('UPDATE_QUIZ_COMPLETE', false);
			} else {
				context.commit('UPDATE_QUIZ_COMPLETE', true);
			}
		}
	},
	getters: {
	}
});