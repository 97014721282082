import { createRouter, createWebHashHistory } from "vue-router";
import store from "./store";

const routes =  [
  {
    path: "/",
    alias: "/login",
    name: "login",
    component: () => import("./components/Login")
  },
  {
    path: "/beers",
    name: "beers",
    redirect: "/select-brewery",
    component: () => import("./components/BeersList")
  },
  {
    path: "/beers/:brewery",
    name: "brewery-beers",
    component: () => import("./components/BeersList")
  },
  {
    path: "/select-brewery",
    name: "select-brewery",
    component: () => import("./components/SelectBrewery")
  },
  {
    path: "/add-beer",
    name: "add-beer",
    component: () => import("./components/AddBeer")
  },
  {
    path: "/create-account",
    name: "create-account",
    component: () => import("./components/CreateAccount")
  },
  {
    path: "/quiz",
    name: "quiz",
    component: () => import("./components/Quiz")
  },
  {
    path: "/brewery-admin",
    name: "brewery-admin",
    component: () => import("./components/BreweryAdmin")
  },
  {
    path: "/add-brewery",
    name: "add-brewery",
    component: () => import("./components/AddBrewery")
  },
  {
    path: "/models",
    name: "models",
    component: () => import("./components/Models")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.name == 'quiz'
    && store.state.quizComplete) next({ name: 'select-brewery' })
  else next()
});

export default router;
