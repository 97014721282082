import http from "../http-common";
import store from "../store";

class BeerDataService {
  getAllBeers(user_id, brewery_id, beer_scores) {
    return http.get(`/beers/${user_id}/${brewery_id}/${beer_scores}/`);
  }

  getAllBeerStyles () {
    return http.get(`/beer_styles/`);
  }

  getAllStates () {
    return http.get(`/states/`);
  }

  getAllModels () {
    return http.get(`/models/`);
  }

  getAllBreweries (for_select_brewery_dropdown, active_menus_only, user_id) {
    return http.get(`/breweries/${for_select_brewery_dropdown}/${active_menus_only}/${user_id}/`);
  }

  getAllFlavors (for_select_flavor_dropdown) {
    return http.get(`/flavors/${for_select_flavor_dropdown}/`);
  }

  getBeer(id) {
    return http.get(`/beers/${id}/`);
  }

  getUser(phone_or_email) {
    return http.get(`/user/${phone_or_email}`);
  }

  setRating(data) {
    return http.post(`/set_rating/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  updateRating(data) {
    return http.put(`/set_rating/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  login(data) {
    return http.post(`/login/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  logout(data) {
    return http.post(`/logout/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  createBeer(data) {
    return http.post(`/update_beer/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  createMenuItem(data) {
    return http.post(`/update_menu_item/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  updateMenuItem(data) {
    return http.put(`/update_menu_item/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  createBrewery(data) {
    return http.post(`/update_brewery/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  createUser(data) {
    return http.post(`/users/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  authUser() {
    return http.get(`/auth/`, {
      headers: {
        'Content-type': "application/json",
        'Authorization': store.state.authHeader,
      }
    })
  }

  updateUserFlavors(id, data) {
    return http.put(`/user_flavors/${id}/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  updateBeer(data) {
    return http.put(`/update_beer/`, data, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  deleteBeer(id) {
    return http.delete(`/beers/${id}/`, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  deleteAllBeers() {
    return http.delete(`/beers/`, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }

  findBeerByTitle(title) {
    return http.get(`/beers?title=${title}/`, {
      headers: {
        'Content-type': "application/json",
        'X-CSRFToken': store.state.csrfToken
      }
    });
  }
}

export default new BeerDataService();